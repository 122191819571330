<template>
  <div>
    <cui-lockscreen />
  </div>
</template>
<script>
import CuiLockscreen from '@/components/cleanui/system/Auth/Lockscreen'
export default {
  components: {
    CuiLockscreen,
  },
}
</script>
